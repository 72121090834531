import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Suspense, lazy, useContext, useEffect } from "react";
import { AppContext } from "./Context/AppContextProvider";
import CatalogueInline from "./Components/CatalogueInline";
import ThreeDProductViewerInline from "./Components/ThreeDProductViewerInline";
import CustomizationMenuInline from "./Components/CustomizationMenuInline";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import { QueryParam } from "./Utilities/queryParam";
import { IframeService } from "./Services/IframeService/IframeService";
import ProductList from "./Components/ProductList";

const Catalogue = lazy(() => import("./Components/Catalogue"));
const CustomizationPopup = lazy(
    () => import("./Components/CustomizationPopup")
);
const UploadAssetPage = lazy(() => import("./Components/UploadAssetPage"));

function App() {
    const loadCustomizationPopup = (brandId: string, category: string, sku: string) => {
        if (!IframeService.scriptLoaded) {
            IframeService.loadScript().then((loaded) => {
                window.customizationStudio.loadCustomizationStudioPopup(
                    brandId,
                    category,
                    sku
                );
            });
        } else {
            window.customizationStudio.loadCustomizationStudioPopup(
                brandId,
                category,
                sku
            );
        }
    }

    const loadInlineCustomization = (brandId: string, category: string, sku: string, threeDViewerIframeParentId: string, customizationMenuIframeParentId: string) => {
        if (!IframeService.scriptLoaded) {
            IframeService.loadScript().then((loaded) => {
                window.customizationStudio.loadCustomizationStudio(
                    brandId,
                    category,
                    sku,
                    threeDViewerIframeParentId,
                    customizationMenuIframeParentId
                );
            });
        } else {
            window.customizationStudio.loadCustomizationStudio(
                brandId,
                category,
                sku,
                threeDViewerIframeParentId,
                customizationMenuIframeParentId
            );
        }
    }

    useEffect(() => {
        const requiredData = QueryParam.getParam('requiredData')
        const isInline = QueryParam.getParam('isInline')
        if (requiredData) {
            const data = requiredData.split('#')
            const brandId = data[0].split('=')[1]
            const category = data[1].split('=')[1]
            const sku = data[2].split('=')[1]
            if (isInline) {
                const threeDViewerIframeParentId = data[3].split('=')[1]
                const customizationMenuIframeParentId = data[4].split('=')[1]
                loadInlineCustomization(brandId, category, sku, threeDViewerIframeParentId, customizationMenuIframeParentId)
            } else {
                loadCustomizationPopup(brandId, category, sku)
            }

        }

    }, [])

    const context = useContext(AppContext);

    if (!context) {
        throw new Error("AppContext must be used within a AppContextProvider");
    }

    const { setShowLoadingScreen } = context;
    useEffect(() => {
        setShowLoadingScreen(true)
    }, [])

    return (
        <div className="w-full h-full">
            <Suspense fallback={<></>}>
                <Routes>
                    <Route path="/catalogue" element={<Catalogue />} />
                    <Route path="/catalogue-list" element={<ProductList />} />
                    <Route path="/catalogue-inline" element={<CatalogueInline />} />
                    <Route path="/" element={<CustomizationPopup />} />
                    <Route path="/3dViewer" element={<ThreeDProductViewerInline />} />
                    <Route path="/customization-menu" element={<CustomizationMenuInline />} />
                    <Route path="/upload-asset" element={<PrivateRoute Component={UploadAssetPage} />} />
                </Routes>
            </Suspense>
        </div>
    );
}

export default App;
