import React, { useEffect, useState } from 'react'
import { ApiService } from '../../Services/apiServices'

const PrivateRoute = ({
    Component
}: {
    Component: any
}) => {
    const [authorized, setAuthorized] = useState(false)
    useEffect(() => {
        (async () => {
            // const user = await ApiService.authenticateUser()
            // if (user.status === 200)
            //     else
            // setAuthorized(false)
            setAuthorized(true)
        })()
    })

    return authorized ? <Component /> : null
}

export default PrivateRoute