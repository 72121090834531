class QueryParam {
    private allowedKeys: string[];
    private queryParams: URLSearchParams;
    private localParam: { [key: string]: string };

    constructor() {
        this.allowedKeys = ["sku", "category", "brand_id", "type", "selectedConfig", "requiredData", "isInline", "access-token"];
        if (window?.location?.hash) {
            const hashParams = window.location.hash
            const extractSearchParams = '?' + hashParams?.split('?')[1]
            this.queryParams = new URLSearchParams(extractSearchParams)
        } else {
            this.queryParams = new URLSearchParams(window.location.search);
        }
        this.localParam = {};
    }

    getParam(key: string, force: boolean = false): string | null {
        if (this.allowedKeys.includes(key)) {
            return force ? this.queryParams.get(key) : (this.localParam[key] || this.queryParams.get(key));
        } else {
            return null;
        }
    }

    setParam(key: string, value: string): string | null {
        if (this.allowedKeys.includes(key)) {
            this.localParam[key] = value;
            return this.localParam[key];
        } else {
            return null;
        }
    }

    getNotAllowedParam(key: string, force: boolean = false): string | null {
        return force ? this.queryParams.get(key) : (this.localParam[key] || this.queryParams.get(key));
    }
}

const queryParamObject = new QueryParam();
export { queryParamObject as QueryParam };
