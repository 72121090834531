import { useContext, useEffect, useState } from "react"
import { CopyIcon, ShareLinkPopupCloseIcon } from "./Icons/Icons";
import { AppContext } from "../Context/AppContextProvider";
import { getShareableLink } from "../Utilities/sharedFunctions";

const ShareLinkPopup = () => {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error("AppContext must be used within a AppContextProvider");
    }
    const { setShowShareLinkPopup, setShowNotification, setNotification } = context;
    const [shareableUrl, setShareableUrl] = useState('')
    const copyLink = async () => {
        try {
            await navigator.clipboard.writeText(shareableUrl);
            setNotification({
                message: 'Link copied',
                type: 'success'
            })
            handleShowNotification(true)
            setShowShareLinkPopup(false)
        } catch (error) {
            console.log(error)
        }
    }

    const handleShowNotification = (value: boolean) => {
        setShowNotification(value)
        if (value) {
            setTimeout(() => {
                setShowNotification(false);
            }, 1500);
        }
    }

    const closePopup = (e: any) => {
        if (e.target.classList.contains('popup-overlay') || e.target.classList.contains('close-popup')) {
            setShowShareLinkPopup(false);
            return
        }
    }

    useEffect(() => {
        const url = getShareableLink()
        setShareableUrl(url)
    }, [])

    return (
        <div className='popup-overlay absolute top-0 left-0 w-full h-full bg-[rgba(28,28,28,0.30)] flex justify-center items-center z-[1000]' onClick={(e) => closePopup(e)} >
            <div className='relative w-[28.125rem] bg-white rounded-[0.75881rem] flex flex-col gap-[0.8rem] items-center px-[3.13rem]'>
                <div className='text-[1rem] font-semibold leading-normal mt-[1.75rem]'>Share</div>
                <div className="cursor-pointer  absolute top-[0.81rem] right-[1.25rem]" onClick={(e) => closePopup(e)}>
                    <ShareLinkPopupCloseIcon />
                </div>
                <div className="flex flex-col gap-[0.56rem]">
                    <div className="text-[1rem] font-semibold leading-normal">Page Link</div>
                    <div className="relative w-[21.875rem] rounded-[0.375rem] border-[0.655px] border-[rgba(0,0,0,0.12)] flex items-center mb-[1.31rem]">
                        <input type="text" name="" id="" value={shareableUrl} className="w-[85%] py-[0.69rem] pl-[0.68rem] outline-none" readOnly />
                        <div className="absolute right-[0.74rem] cursor-pointer" onClick={copyLink}><CopyIcon /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShareLinkPopup