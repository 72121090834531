import { useContext } from "react";
import { AppContext } from "../Context/AppContextProvider";

export const Notification = () => {
    const context = useContext(AppContext)
    if (!context) {
        throw new Error("AppContext must be used within a AppContextProvider");
    }

    const { notification } = context;

    return (
        <div className="absolute w-full flex justify-center top-[1rem]">
            <div className={`${notification?.type == 'success' ? 'bg-black' : 'bg-red-500'} min-w-[200px] text-[0.7rem] px-[1rem] py-[0.4rem]  rounded text-white flex justify-center z-[1000]`}>
                {notification?.message}
            </div>
        </div>
    );
};