import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDIlIjyL4z1gYcJq-8ogl_lPSD3L0XOSUY",
  authDomain: "mirrar-customization-studio.firebaseapp.com",
  databaseURL: "https://dynamic-database.asia-southeast1.firebasedatabase.app/",
  projectId: "mirrar-customization-studio",
  storageBucket: "mirrar-customization-studio.appspot.com",
  messagingSenderId: "492734621690",
  appId: "1:492734621690:web:bbe3dcdfc4deb34c682643",
  measurementId: "G-ZX7GFH9SC5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
export default db