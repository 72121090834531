import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { BACKEND_HOST } from "../Constants/backend";
import { ThreeDViewer } from "./Viewer";
import { QueryParam } from "../Utilities/queryParam";

interface ApiResponse<T> {
    status: number;
    data: T;
}

class ApiService {
    brandId: string;
    brandSettingResponse: Record<string, AxiosResponse<any>> = {};
    userIdLoginResponse: Record<string, AxiosResponse<any>> = {};
    userIdAuthResponse: Record<string, AxiosResponse<any>> = {};
    categoryProductResponse: Record<string, AxiosResponse<any>> = {};
    categoriesOnBrand: Record<string, any> = {};
    searchVariantWithOnlyVariantResponse: Record<string, AxiosResponse<any>> =
        {};
    brandBucketName: string = ""

    constructor() {
        this.brandId =
            QueryParam.getParam("brand_id") ||
            "337695fe-f65b-4971-98e6-3e4c2449be78";
    }

    getBrandDetails = async (): Promise<AxiosResponse<any> | undefined> => {
        try {
            if (this.brandSettingResponse[this.brandId]) {
                return this.brandSettingResponse[this.brandId];
            }
            const url = `${BACKEND_HOST}/brandSetting/get/brand/${this.brandId}`;
            const response = await axios.get(url);
            if (response?.status === 200) {
                this.brandSettingResponse[this.brandId] = response;
            }
            return response;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    };

    userIdLogin = async (): Promise<AxiosResponse<any> | undefined> => {
        try {
            if (this.userIdLoginResponse[this.brandId]) {
                return this.userIdLoginResponse[this.brandId];
            }
            const url = `${BACKEND_HOST}/brand/brand/${this.brandId}/id-login`;
            const response = await axios.get(url);
            if (response?.status === 200) {
                this.userIdLoginResponse[this.brandId] = response;
                if (response?.data?.data?.brandDetails?.brandBucketName) {
                    this.brandBucketName = response?.data?.data?.brandDetails?.brandBucketName
                }
            }
            return response;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    };

    userIdAuth = async (): Promise<AxiosResponse<any> | void> => {
        try {
            if (this.userIdAuthResponse[this.brandId]) {
                return this.userIdAuthResponse[this.brandId];
            }
            const data = JSON.stringify({ brandId: this.brandId });

            const config: AxiosRequestConfig = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${BACKEND_HOST}/brand/auth`,
                headers: { "Content-Type": "application/json" },
                data,
            };

            const response = await axios.request(config);
            if (response?.status === 200) {
                this.userIdAuthResponse[this.brandId] = response;
                if (response?.data?.data?.brandBucketName) {
                    this.brandBucketName = response?.data?.data?.brandBucketName
                }
            }
            return response;
        } catch (error) {
            throw new Error('error');
        }
    };

    getCategoryProducts = async (
        params: any,
        category: string
    ): Promise<AxiosResponse<any> | any> => {
        try {
            const key = JSON.stringify(params) + category;
            if (this.categoryProductResponse[key]) {
                return this.categoryProductResponse[key];
            }
            const url = `${BACKEND_HOST}/product/brand/${this.brandId}/categories/${category}/inventories`;
            const response = await axios.post(url, params);
            if (response?.status === 200) {
                this.categoryProductResponse[key] = response;
            }
            return response;
        } catch (error) {
            console.error(error);
            return error;
        }
    };

    getAllCategories = async (): Promise<any> => {
        try {
            if (this.categoriesOnBrand[this.brandId]) {
                return this.categoriesOnBrand[this.brandId];
            }
            const url = `${BACKEND_HOST}/category/get/brand/${this.brandId}`;
            const response = await axios.get(url);
            if (response?.data?.status === 200) {
                this.categoriesOnBrand[this.brandId] = response?.data?.data;
                return response?.data?.data;
            } else {
                throw new Error("Unexpected response");
            }
        } catch (error) {
            console.error(error);
            return error;
        }
    };

    searchVariantWithOnlyVariant = async (
        sku: string
    ): Promise<AxiosResponse<any> | undefined> => {
        try {
            const key = this.brandId + sku;
            if (this.searchVariantWithOnlyVariantResponse[key]) {
                return this.searchVariantWithOnlyVariantResponse[key];
            }
            const url = `${BACKEND_HOST}/product/brand/${this.brandId}/variant/variantSku/${sku}`;
            const response = await axios.get(url);
            if (response?.status === 200) {
                this.searchVariantWithOnlyVariantResponse[key] = response;
            }
            return response;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    };

    sendEmailForRequestDetail = async (
        param: any
    ): Promise<AxiosResponse<any> | any> => {
        try {
            const url = `${BACKEND_HOST}/customization/brand/${this.brandId}/send-email`;
            const response = await axios.post(url, param);
            if (response?.data?.status === 200) {
                return response;
            } else {
                throw new Error("Unexpected response status");
            }
        } catch (error) {
            throw error;
        }
    };
    authenticateUser = async () => {
        try {
            const myHeaders = new Headers();
            const token = QueryParam.getParam("access-token");
            myHeaders.append("Authorization", `Bearer ${token}`);

            const output = await fetch(
                `${BACKEND_HOST}/webhook/authenticate-token`,
                {
                    method: "POST",
                    headers: myHeaders,
                    redirect: "follow",
                }
            );
            const result = await output.json();
            return result;
        } catch (error) {
            return null;
        }
    };

    uploadImageToS3 = async (formData: FormData) => {
        try {
            const myHeaders = new Headers();

            const output = await fetch(
                `${BACKEND_HOST}/upload/brand/${this.brandId}/image`,
                {
                    method: "POST",
                    headers: myHeaders,
                    body: formData,
                    redirect: "follow",
                }
            );
            const result = await output.json();
            return result;
        } catch (error) {
            throw error;
        }
    };

    uploadFileToS3 = async (formData: FormData) => {
        try {
            const output = await fetch(
                `https://staging-ar-api.mirrar.com/upload/brand/${this.brandId}/file`,
                {
                    method: "POST",
                    body: formData,
                    redirect: "follow",
                }
            );
            const result = await output.json();
            return result;
        } catch (error) {
            console.error(error);
        }
    };

    getCapturedImage(): Promise<string> {
        return new Promise(async (resolve, reject) => {
            try {
                const imageData = await ThreeDViewer.getImageData();
                const image = imageData.replace("data:image/png;base64,", "");
                const formData = new FormData();
                formData.append("image", image);

                const response = await axios.post(
                    "https://m.mirrar.com/api/v1/image/base64/upload",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (response.status === 200) {
                    const imageUrl = response.data.url;
                    resolve(imageUrl);
                } else {
                    reject(new Error("Failed to upload image"));
                }
            } catch (error) {
                reject(new Error("Failed to upload image"));
            }
        });
    }

    extractLibFiles = async (folderPath: string) => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "folderPath": "presets/"
            });

            const value = await fetch(`https://staging-ar-api.mirrar.com/customization/brand/${this.brandId}/${folderPath}`, {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            })
            const output = await value.json()
            return output.data.files
        } catch (error) {
            console.error(error);

            return []
        }
    }
}

const apiService = new ApiService();

export { apiService as ApiService };
