import {
    ViewerApp,
    addBasePlugins,
    AssetManagerBasicPopupPlugin,
    VariationConfiguratorPlugin,
    GroundPlugin,
    CameraViewPlugin,
    InteractionPromptPlugin,
    Color,
    AssetManagerPlugin,
    CanvasSnipperPlugin,
    FileTransferPlugin,
    IOrthographicCameraOptions,
    IPerspectiveCameraOptions,
    DiamondPlugin,
    ToneMapping,
    BloomPlugin,
    TonemapPlugin,
    Texture,
    ACESFilmicToneMapping,
    GBufferPlugin,
    PresetLibraryPlugin,
    SSRPlugin,
} from "webgi";
import { Config, Configurator } from "../Interfaces";

class ThreeDViewer {
    viewer: ViewerApp | null;
    config: VariationConfiguratorPlugin | null;
    defaultSelectedConfigs: any;
    controls: any;
    options: any | null;
    manager: AssetManagerPlugin | undefined
    canvasPlugin: CanvasSnipperPlugin | any;
    fileTransfer: any;
    currentProductConfigFile: Configurator | null
    diamondPlugin: DiamondPlugin | undefined
    globalConfig: any | undefined

    constructor() {
        this.viewer = null;
        this.config = null;
        this.controls = null;
        this.options = null;
        this.defaultSelectedConfigs = null;
        this.canvasPlugin = null;
        this.currentProductConfigFile = null
    }

    async setupViewer(
        id: string,
        currentProductConfig: Configurator
    ): Promise<void> {
        this.globalConfig = currentProductConfig.globalConfig
        this.currentProductConfigFile = currentProductConfig
        this.viewer = new ViewerApp({
            canvas: document.getElementById(id) as HTMLCanvasElement,
            useGBufferDepth: true,
            isAntialiased: false
        });
        this.viewer.renderer.displayCanvasScaling = 2

        this.manager = await this.viewer.addPlugin(AssetManagerPlugin);
        await this.viewer.addPlugin(CameraViewPlugin)
        await this.viewer.addPlugin(GBufferPlugin)
        await this.viewer.addPlugin(SSRPlugin)

        // const presetLibPlugin = await this.viewer.addPlugin(PresetLibraryPlugin);
        // presetLibPlugin.loadPresetGroups(presetLibPlugin.presetGroups)
        // presetLibPlugin.presetGroups[0].apply(this.viewer)

        const config = await this.viewer.addPlugin(VariationConfiguratorPlugin);
        this.canvasPlugin = await this.viewer.addPlugin(CanvasSnipperPlugin);

        await this.viewer.addPlugin(FileTransferPlugin);

        await config.importConfig(currentProductConfig.config);
        this.config = config;

        await this.viewer.addPlugin(AssetManagerBasicPopupPlugin);
        await addBasePlugins(this.viewer);

        this.viewer.renderer.refreshPipeline();
        if (this.globalConfig?.["presets"]) {
            await this.viewer.load(this.globalConfig["presets"])
        } else {
            await this.viewer.load("https://mirrar-medialibrary.s3.ap-south-1.amazonaws.com/customization-studio/global-library/presets/ss-020.vjson")
        }

        const ssr = this.viewer.getPlugin(SSRPlugin)?.passes.ssr.passObject
        if (ssr) {
            if (this.globalConfig?.["srr-intensity"]) {
                ssr.intensity = Number(this.globalConfig["srr-intensity"]) || 1
            }
        }

        // await this.viewer.load("./assets/model/engagementring-configurator.glb");
        // await this.viewer.setEnvironmentMap("./assets/lightmap-r-2.hdr");
        await this.applyMetalEnvFile(currentProductConfig.environmentMapUrl || "./assets/p360-01.hdr");


        // const materialEnvMap = await manager?.importer?.importSinglePath(currentProductConfig?.environmentMapUrl) as Texture;
        // const materialEnvMap = await manager?.importer?.importSinglePath(currentProductConfig.environmentMapUrl || "https://mirrar-medialibrary.s3.ap-south-1.amazonaws.com/customization-studio/brand-testing/Earrings/E0001306163/hdr/Metal.png") as Texture;
        // this.viewer.scene.environment = materialEnvMap || null;

        const diamondEnvMap = await this.manager?.importer?.importSinglePath(currentProductConfig.gemEnvironmentMapUrl || "./assets/gem-3.hdr") as Texture;
        // const diamondEnvMap = await this.manager?.importer?.importSinglePath("./assets/env-gem.hdr") as Texture;
        this.diamondPlugin = await this.viewer.addPlugin(DiamondPlugin)
        this.diamondPlugin.envMap = diamondEnvMap

        await this.manager.addFromPath(currentProductConfig?.threeDProductUrl);

        await this.applyConfigs(currentProductConfig.config);

        //Bake shadows once after model loaded and enable rendering
        // const ground = this.viewer.getPlugin(GroundPlugin);
        // if (ground) {
        //     ground.visible = true;
        //     ground.autoBakeShadows = false;
        //     ground.bakeShadows();
        //     ground.tonemapGround = true
        //     if (ground.shadowBaker)
        //         ground.shadowBaker.groundMapMode = 'alphaMap'
        // }
        // this.viewer.scene.setBackgroundColor(new Color('#FFF').convertSRGBToLinear())
        // const toneMapping = this.viewer.getPlugin(TonemapPlugin);
        // if (toneMapping?.config) {
        //     // toneMapping.config.clipBackground = true;
        //     toneMapping.config.toneMapping = ACESFilmicToneMapping
        // }

        const options = this.viewer.scene.activeCamera.getCameraOptions();
        if (this.globalConfig?.['default-position']) {
            options.position = this.globalConfig['default-position']
        }
        if (this.globalConfig?.['zoom-value']) {
            options.zoom = Number(this.globalConfig['zoom-value']);
        }
        if (window.screen.width > 768 && this.globalConfig?.['zoom-value-desktop']) {
            options.zoom = Number(this.globalConfig['zoom-value-desktop'])
        }


        this.viewer.scene.activeCamera.setCameraOptions(options);
        const interaction = this.viewer.getPlugin(InteractionPromptPlugin);
        if (interaction) {
            interaction.enabled = false;
        }

        const controls = this.viewer.scene.activeCamera.controls;

        if (controls) {
            if (this.globalConfig?.['auto-rotate']) {
                controls.autoRotate = this.globalConfig?.['auto-rotate'];
                (controls as any).minDistance = Number(this.globalConfig['min-zoom-dist']);
            }
        } else {
            console.warn("Controls are undefined.");
        }

        this.defaultSelectedConfigs = JSON.parse(
            JSON.stringify(config.variations)
        );
        this.controls = controls;
        this.options = options;
        await this.viewer.fitToView()
    }

    //not used for now
    async setBackgroundColor(color: string) {
        if (!this.viewer) return;
        this.viewer.scene.setBackgroundColor(new Color(color));
        this.viewer.scene.backgroundIntensity = 4;
    }

    async applyConfigs(currentProductConfig: any) {
        // Using optional chaining
        const variationsKeys = this.config?.variations
            ? Object.keys(this.config.variations)
            : [];

        const configPromises = variationsKeys.map(async (key) => {
            const variations = (this.config as any).variations?.[key];
            const applyVariationPromises = variations.map(
                async (config: any, index: number) => {
                    let configData: any
                    if (key === "materials")
                        configData = this.currentProductConfigFile?.config[key][index]
                    const selectedProperty =
                        currentProductConfig[key][index]?.selected;
                    if (selectedProperty !== undefined) {
                        await this.config?.applyVariation(
                            config,
                            selectedProperty,
                            (key as any)
                        );
                        if (key === "materials" && configData) {
                            const fileType = configData.items?.[selectedProperty]?.split('.')[1]
                            if (fileType === "pmat") {
                                const envFile = configData.environmentFiles?.[index]
                                envFile && await this.applyMetalEnvFile(envFile)
                            } else if (fileType === "dmat") {
                                const envFile = configData.environmentFiles?.[index]
                                envFile && await this.applyGemEnvFile(envFile)
                            }
                        }
                    }
                }
            );
            await Promise.all(applyVariationPromises);
        });

        await Promise.all(configPromises);
    }
    async interactionPrompt() {
        let interaction = this.viewer?.getPlugin(InteractionPromptPlugin);
        if (interaction) {
            let div = document.createElement("div");
            let originalCursorEl = interaction.cursorEl;
            interaction.cursorEl = div;
            interaction.enabled = true;
            interaction?.startAnimation?.();
            interaction.enabled = false;
            div.remove?.();
            interaction.cursorEl = originalCursorEl;
        }
    }
    async fitToView() {
        await this.viewer?.fitToView();
    }

    async applyMetalEnvFile(file: string) {
        if (!this.viewer) return
        await this.viewer.setEnvironmentMap(file || "./assets/p360-01.hdr");
        this.viewer.scene.fixedEnvMapDirection = true
    }

    async applyGemEnvFile(file: string) {
        if (!this.viewer || !this.manager || !this.diamondPlugin) return
        const diamondEnvMap = await this.manager.importer?.importSinglePath(file || "./assets/gem-3.hdr") as Texture;
        this.diamondPlugin.envMap = diamondEnvMap
        // this.diamondPlugin.forceSceneEnvMap = true
        this.diamondPlugin.envMapRotation = 360 / 2
    }

    async resetChanges() {
        const variations = this.defaultSelectedConfigs;
        Object.keys(variations).map(async (key) => {
            const configs = variations[key];
            for await (let config of configs) {
                if (key == "materials") {
                    await this.applyMaterialVariation(
                        config.name,
                        config.selected
                    );
                } else {
                    await this.applyObjectVariation(
                        config.name,
                        config.selected
                    );
                }
            }
        });

        this.interactionPrompt();
    }

    async applyObjectVariation(
        category: string,
        index: number,
        callback = () => { }
    ): Promise<void> {
        if (this.config) {
            const cat = this.config.variations.objects.find((cat: any) => {
                return cat.name === category;
            });
            if (cat) {
                await this.config.applyVariation(cat, index, "objects");
            }
            callback();
        }
    }

    async applyMaterialVariation(
        category: string,
        index: number,
        callback = () => { }
    ): Promise<void> {
        if (this.config) {
            const cat = this.config.variations.materials.find((cat: any) => {
                return cat.name === category;
            });
            const materialData = this.currentProductConfigFile?.config.materials.find(item => item.name === category)
            console.log(materialData, index);

            if (cat) {
                await this.config.applyVariation(cat, index, "materials");
                if (materialData) {
                    const fileType = materialData.items?.[index]?.split('.')[1]
                    if (fileType === "pmat") {
                        const envFile = materialData.environmentFiles?.[index]
                        envFile && await this.applyMetalEnvFile(envFile)
                    } else if (fileType === "dmat") {
                        const envFile = materialData.environmentFiles?.[index]
                        envFile && await this.applyGemEnvFile(envFile)
                    }
                }
            }
            callback();
        }
    }

    handleZoomInZoomOut(value: boolean): void {
        if (value) {
            this.controls.zoomIn(1);
        } else {
            this.controls.zoomOut(1);
        }
        this.controls.update();
    }

    handleAutorotate(value: boolean): void {
        if (this.controls) {
            this.controls.autoRotate = value;
        }
    }

    async downloadScreenshot() {
        this.canvasPlugin.downloadSnapshot("screenshot.png", {
            scale: 2,
            // displayPixelRatio: 4,
        });
    }

    async getImageData() {
        return await this.canvasPlugin.getDataUrl({
            scale: 2,
            // displayPixelRatio: 4,
        });
    }
}

const threeDViewer = new ThreeDViewer();
export { threeDViewer as ThreeDViewer };
