import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../Context/AppContextProvider";
import { QueryParam } from "../../Utilities/queryParam";
import { BrandDetails } from "../../Utilities/brandSettings";
import { ApiService } from "../../Services/apiServices";
import { onValue, ref } from "firebase/database";
import db from "../../Services/Firebase/Config";
import { getData } from "../../Utilities/sharedFunctions";

interface ProductConfigurations {
    isConfigActive?: boolean;
    config?: {
        materials?: Array<{ name: string; selected: number }>;
        objects?: Array<{ name: string; selected: number }>;
    };
    // Add other relevant properties here
}

const CommonSettings = () => {
    const context = useContext(AppContext);
    const [brandSettingsLoaded, setBrandSettingsLoaded] = useState(false)

    if (!context) {
        throw new Error("AppContext must be used within a AppContextProvider");
    }

    const {
        currentProduct,
        setCurrentProduct,
        setShowNoProductPopup,
        currentCategory,
        setCurrentCategory,
        setCurrentProductConfig,
        setCurrentProductConfigCopy,
        setCurrentDummyProductConfig,
        setShowLoadingScreen,
        setShowError,
    } = context;
    const getStringParam = (
        param: string | null,
        defaultValue: string = ""
    ): string => {
        return param ?? defaultValue;
    };

    const brandId: string = getStringParam(QueryParam.getParam("brand_id"), "337695fe-f65b-4971-98e6-3e4c2449be78");
    const category: string = getStringParam(QueryParam.getParam("category"));
    const sku: string = getStringParam(QueryParam.getParam("sku"), "UP2314FQTAA00");
    const setAllBrandDetails = async () => {
        await BrandDetails.setBrandDetails();
        setBrandSettingsLoaded(true)
    };

    const setData = async () => {
        await getAndSetCategoryBasedOnProvidedSku();
    };

    const getAndSetCategoryBasedOnProvidedSku = async () => {
        const params = {
            limit: 20,
            product_code: sku,
            filter_field: {
                page: 1,
                isSetOnly: [false],
            },
        };
        if (!sku) {
            // if no sku is passed
            setShowLoadingScreen(false);
            setShowError(true);
        } else if (sku && !category) {
            // search the given sku to fetch the category first
            let searchResponse = await ApiService.searchVariantWithOnlyVariant(
                sku
            );
            if (
                searchResponse?.status === 200 &&
                searchResponse?.data?.data?.length
            ) {
                const categoryFromApi =
                    searchResponse?.data?.data?.[0]?.products?.[0]?.category;
                getProduct(params, categoryFromApi.categoryKey);
            } else {
                setShowNoProductPopup(true);
                setShowLoadingScreen(false);
                setShowError(true);
            }
        } else if (sku && category) {
            getProduct(params, category);
        } else if (!sku) {
            setShowNoProductPopup(true);
        }
    };

    const getProduct = async (params: Object, categoryKey: string) => {
        const response = await ApiService.getCategoryProducts(
            params,
            categoryKey
        );
        if (response && response.status === 200 && response?.data?.data) {
            if (response?.data?.data?.[0]?.variants?.[0]?.variantSku === sku) {
                const categoryFromApi = response?.data?.data?.[0]?.category;
                setCurrentCategory(categoryFromApi);
                setCurrentProduct(response?.data?.data?.[0]);
            }
        }
    };

    const updateConfig = (selectedConfigs: any, productConfigurations: any) => {
        const properties = selectedConfigs.split("%");
        properties.forEach((data: string) => {
            if (data.startsWith("materials-")) {
                const [_, materialName, selectedIndex] = data.split("-");
                const material = productConfigurations?.config?.materials.find(
                    (item: any) => item.name === materialName
                );
                if (material) {
                    material.selected = Number(selectedIndex);
                }
            } else if (data.startsWith("objects-")) {
                const [_, objectName, selectedIndex] = data.split("-");
                const object = productConfigurations?.config?.objects.find(
                    (item: any) => item.name === objectName
                );
                if (object) {
                    object.selected = Number(selectedIndex);
                }
            }
        });
        return productConfigurations;
    };

    useEffect(() => {
        const fetchProductConfigurations = async () => {
            if (currentCategory.categoryKey && currentProduct.productHandle && brandSettingsLoaded) {
                try {
                    const productConfigurations = await getData(
                        `${BrandDetails.brandData.brandBucketName}/${currentCategory.categoryKey}/${currentProduct.productHandle}`
                    ) as ProductConfigurations;
                    if (
                        productConfigurations &&
                        typeof productConfigurations === "object"
                    ) {
                        const keys = Object.keys(
                            productConfigurations as object
                        );

                        if (keys.length > 0) {
                            const selectedConfig =
                                QueryParam.getParam("selectedConfig");
                            if (selectedConfig && selectedConfig.length) {
                                const updatedConfig = updateConfig(
                                    selectedConfig,
                                    productConfigurations
                                );
                                setCurrentProductConfig(updatedConfig);
                                setCurrentProductConfigCopy(updatedConfig);
                            } else {
                                setCurrentProductConfig(productConfigurations);
                                setCurrentProductConfigCopy(
                                    productConfigurations
                                );
                                if (!productConfigurations?.isConfigActive) {
                                    setShowLoadingScreen(false);
                                    setShowError(true);
                                }
                            }
                        }
                    } else {
                        setShowLoadingScreen(false);
                        setShowError(true);
                    }
                } catch (error) {
                    console.error(
                        "Error fetching product configurations:",
                        error
                    );
                }
            }
        };

        fetchProductConfigurations();
    }, [currentCategory, currentProduct, brandSettingsLoaded]);

    useEffect(() => {
        const fetchDummyProductConfigurations = async () => {
            try {
                const dummyProductConfigurations = await getData(
                    `Rings/DummyData`
                );
                if (
                    dummyProductConfigurations &&
                    typeof dummyProductConfigurations === "object"
                ) {
                    const keys = Object.keys(
                        dummyProductConfigurations as object
                    );

                    if (keys.length > 0) {
                        setCurrentDummyProductConfig(
                            dummyProductConfigurations
                        );
                    }
                }
            } catch (error) {
                console.error(
                    "Error fetching Dummy product configurations:",
                    error
                );
            }
        };

        fetchDummyProductConfigurations();
    }, []);


    useEffect(() => {
        (async () => {
            ApiService.brandId = brandId;
            try {
                await setAllBrandDetails();
                await setData();
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);
    return <></>;
};

export default CommonSettings;
