import React, {
    createContext,
    ReactNode,
    useState,
    Dispatch,
    SetStateAction,
    FC,
    useEffect,
} from "react";
import { BrandDetails } from "../Utilities/brandSettings";

interface NotificationType {
    message: string;
    type: "success" | "error";
}
interface AppContextType {
    showLoadingScreen: boolean;
    setShowLoadingScreen: Dispatch<SetStateAction<boolean>>;
    currentCategory: any;
    setCurrentCategory: Dispatch<SetStateAction<any>>;
    currentProduct: any;
    setCurrentProduct: Dispatch<SetStateAction<any>>;
    currentProductConfig: any;
    setCurrentProductConfig: Dispatch<SetStateAction<any>>;
    currentProductConfigCopy: any;
    setCurrentProductConfigCopy: Dispatch<SetStateAction<any>>;
    showNoProductPopup: boolean;
    setShowNoProductPopup: Dispatch<SetStateAction<boolean>>;
    brandDetail: any;
    discardChanges: boolean;
    setDiscardChanges: Dispatch<SetStateAction<boolean>>;
    currentDummyProductConfig: any;
    setCurrentDummyProductConfig: Dispatch<SetStateAction<any>>;
    isFullScreen: boolean;
    setIsFullScreen: Dispatch<SetStateAction<any>>;
    showFullscreen: boolean;
    setShowFullscreen: Dispatch<SetStateAction<any>>;
    tooltipFinishedValue: boolean;
    setTooltipFinishedValue: Dispatch<SetStateAction<any>>;
    currentFlow: any;
    setCurrentFlow: Dispatch<SetStateAction<any>>;
    toolTipSteps: any;
    setToolTipSteps: Dispatch<SetStateAction<any>>;
    showToolTipInstructions: boolean;
    setShowOrHideToolTipInstructions: Dispatch<SetStateAction<any>>;
    tooltipFinishFunction: any;
    setTooltipFinishFunction: Dispatch<SetStateAction<any>>;
    showError: boolean;
    setShowError: Dispatch<SetStateAction<any>>;
    showShareLinkPopup: boolean;
    setShowShareLinkPopup: Dispatch<SetStateAction<any>>;
    showRequestDetailsPopup: boolean;
    setShowRequestDetailsPopup: Dispatch<SetStateAction<any>>;
    showNotification: boolean;
    setShowNotification: Dispatch<SetStateAction<any>>;
    notification: NotificationType | null;
    setNotification: Dispatch<SetStateAction<NotificationType | null>>;
    selectedCustomizationOptions: any;
    setSelectedCustomizationOptions: Dispatch<SetStateAction<any>>;
    showInformation: boolean;
    setShowInformation: Dispatch<SetStateAction<any>>;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

interface Props {
    children: ReactNode;
}

const AppContextProvider: FC<Props> = ({ children }) => {
    const [showLoadingScreen, setShowLoadingScreen] = useState<any>('');
    const [currentProduct, setCurrentProduct] = useState<any>({});
    const [showNoProductPopup, setShowNoProductPopup] =
        useState<boolean>(false);
    const [currentCategory, setCurrentCategory] = useState<any>({});
    const [currentProductConfig, setCurrentProductConfig] = useState<any>({});
    const [currentProductConfigCopy, setCurrentProductConfigCopy] =
        useState<any>({});
    const [brandDetail, setBrandDetail] = useState<any>({});
    const [discardChanges, setDiscardChanges] = useState<boolean>(false);
    const [currentDummyProductConfig, setCurrentDummyProductConfig] =
        useState<any>({});
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
    const [showFullscreen, setShowFullscreen] = useState<boolean>(false);
    const [tooltipFinishedValue, setTooltipFinishedValue] =
        useState<boolean>(false);
    const [currentFlow, setCurrentFlow] = useState<any>({});
    const [toolTipSteps, setToolTipSteps] = useState<any>({});
    const [showToolTipInstructions, setShowOrHideToolTipInstructions] =
        useState<any>(false);
    const [tooltipFinishFunction, setTooltipFinishFunction] = useState<any>({});
    const [showError, setShowError] = useState<boolean>(false);
    const [showShareLinkPopup, setShowShareLinkPopup] =
        useState<boolean>(false);
    const [showRequestDetailsPopup, setShowRequestDetailsPopup] =
        useState<boolean>(false);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notification, setNotification] = useState<NotificationType | null>(
        null
    );
    const [selectedCustomizationOptions, setSelectedCustomizationOptions] =
        useState<any>({});
    const [showInformation, setShowInformation] = useState<boolean>(false);
    useEffect(() => {
        BrandDetails.brandDetailsSetter(setBrandDetail);
    }, []);

    useEffect(() => {
        if (showLoadingScreen === false) {
            window?.parent?.postMessage?.(
                {
                    origin: "customization-studio",
                    function: "executeFunction",
                    executeFunction: ['toggleVisibilityContainer', 'toggleVisibilityMenuContainer'],
                    param: [[true], [true]]
                },
                "*"
            )
        }
    }, [showLoadingScreen])
    return (
        <AppContext.Provider
            value={{
                showLoadingScreen,
                setShowLoadingScreen,
                currentProduct,
                setCurrentProduct,
                showNoProductPopup,
                setShowNoProductPopup,
                currentCategory,
                setCurrentCategory,
                currentProductConfig,
                setCurrentProductConfig,
                brandDetail,
                discardChanges,
                setDiscardChanges,
                currentDummyProductConfig,
                setCurrentDummyProductConfig,
                isFullScreen,
                setIsFullScreen,
                showFullscreen,
                setShowFullscreen,
                tooltipFinishedValue,
                setTooltipFinishedValue,
                currentFlow,
                setCurrentFlow,
                toolTipSteps,
                setToolTipSteps,
                showToolTipInstructions,
                setShowOrHideToolTipInstructions,
                tooltipFinishFunction,
                setTooltipFinishFunction,
                currentProductConfigCopy,
                setCurrentProductConfigCopy,
                showError,
                setShowError,
                showShareLinkPopup,
                setShowShareLinkPopup,
                showRequestDetailsPopup,
                setShowRequestDetailsPopup,
                showNotification,
                setShowNotification,
                notification,
                setNotification,
                selectedCustomizationOptions,
                setSelectedCustomizationOptions,
                showInformation,
                setShowInformation,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export { AppContext, AppContextProvider };
