const Discard = () => {
    return (
        <div className="border-[0.374px] border-[#888] rounded-[0.375rem] max-w-[15rem] bg-[#ECECEC] absolute mt-[3.63rem] z-[100]">
            <p className="text-[0.875rem] text-[rgba(28,28,28,0.80)] px-[1rem] py-[0.56rem]">
                Discarded the previous changes!
            </p>
        </div>
    );
};

export default Discard;
