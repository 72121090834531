import CustomizationMenu from './CustomizationMenu'
import CommonSettings from './Shared/CommonSettings'

const CustomizationMenuInline = () => {
    return (
        <>
            <CommonSettings />
            <CustomizationMenu />
        </>
    )
}

export default CustomizationMenuInline