import { useContext, useEffect, useRef, useState } from "react";
import { IframeService } from "../Services/IframeService/IframeService";
import { QueryParam } from "../Utilities/queryParam";
import productImage from "../Assests/ProductDisplay.png";
const CatalogueInline = () => {
    const [brandID, setBrandID] = useState<string>("");
    const [category, setCategory] = useState<string>("");
    const [sku, setSku] = useState<string>("");

    useEffect(() => {
        const brandId = QueryParam.getParam("brand_id") ?? "";
        if (brandId) {
            setBrandID(brandId);
        }
        const paramCategory = QueryParam.getParam("category") ?? "";
        if (paramCategory) {
            setCategory(paramCategory);
        }
        const paramSku = QueryParam.getParam("sku") ?? "";
        if (paramSku) {
            setSku(paramSku);
        }
        loadThreeDViewer()
    }, []);

    const loadThreeDViewer = () => {
        const brandId = QueryParam.getParam("brand_id")
        const paramCategory = QueryParam.getParam("category")
        const paramSku = QueryParam.getParam("sku") || sku
        if (!IframeService.scriptLoaded) {
            IframeService.loadScript().then((loaded) => {
                window.customizationStudio.loadCustomizationStudio(
                    brandId || brandID,
                    paramCategory || category,
                    paramSku || sku,
                    "threeDViewer-inline",
                    "threeDCustomizationMenu-inline"
                );
            });
        } else {
            window.customizationStudio.loadCustomizationStudio(
                brandId || brandID,
                paramCategory || category,
                paramSku || sku,
                "threeDViewer-inline",
                "threeDCustomizationMenu-inline"
            );
        }
    };

    const loadCustomizationMenu = () => {
        if (!IframeService.scriptLoaded) {
            IframeService.loadScript().then((loaded) => {
                window.customizationStudio.loadCustomizationStudioMenu(
                    brandID,
                    category,
                    sku,
                    "threeDCustomizationMenu-inline"
                );
            });
        } else {
            window.customizationStudio.loadCustomizationStudioMenu(
                brandID,
                category,
                sku,
                "threeDCustomizationMenu-inline"
            );
        }
    };

    return (
        <div className="App flex flex-col gap-[2rem] p-[1rem]">
            <div className="flex lg:gap-[1rem] lg:flex-row flex-col items-start">
                <span>Brand Id:</span>
                <input
                    className="border-[1px]"
                    type="text"
                    name=""
                    id=""
                    value={brandID}
                    onChange={(e) => {
                        setBrandID(e.target.value);
                    }}
                />
            </div>
            <div className="flex lg:gap-[1rem] lg:flex-row flex-col items-start">
                <span>Category(Earrings,Necklaces,Sets,Rings,Bracelets):</span>
                <input
                    className="border-[1px]"
                    type="text"
                    name=""
                    id=""
                    value={category}
                    onChange={(e) => {
                        setCategory(e.target.value);
                    }}
                />
            </div>
            <div className="flex lg:gap-[1rem] lg:flex-row flex-col items-start">
                <span>SKU :</span>
                <input
                    className="border-[1px]"
                    type="text"
                    name=""
                    id=""
                    value={sku}
                    onChange={(e) => {
                        setSku(e.target.value);
                    }}
                />
            </div>
            <div className="flex gap-[20px]">
                <button
                    onClick={loadThreeDViewer}
                    className="w-[5rem] h-[2rem] bg-[black] text-[white] rounded"
                >
                    Try on
                </button>
            </div>
            <div className="flex gap-[50px] sm:flex-col flex-row">
                <div
                    className="w-[640px] h-[480px] sm:h-[300px] aspect-square border rounded-[0.6rem] sm:w-[100%]"
                    id="threeDViewer-inline"
                >
                    <img
                        src={productImage}
                        alt=""
                        className="w-full h-full p-[30px] object-contain"
                    />
                </div>
                {
                    window.screen.width > 768 ?
                        <div
                            className="flex-grow h-[480px] "
                            id="threeDCustomizationMenu-inline"
                        ></div> : ''
                }

                {/* <button onClick={loadCustomizationMenu} className='px-[20px] py-[5px] bg-black text-white'>Customize</button> */}

            </div>
        </div>
    );
};

export default CatalogueInline;
