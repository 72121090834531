import LoaderGIF from "../Assests/loader.gif";
import mirrarLogo from "../Assests/mirrarLogo.svg";

const Loader = () => {
    return (
        <div className="absolute top-0 left-0 flex flex-col justify-center items-center w-full h-full bg-white  rounded-[0.625rem] z-[1000]">
            <div className="flex flex-col  justify-center items-center w-full">
                <img
                    src={LoaderGIF}
                    className="w-[6%] aspect-square mb-[1.5rem] sm:w-[20.4%]"
                    alt="loader"
                    id="loader"
                />
                <span className="text-[1.125rem] sm:text-[1rem]">
                    Loading your Customization Studio
                </span>
            </div>

            <div className="absolute bottom-[2.88rem] sm:bottom-[3.71rem] flex justify-center w-full ">
                <img
                    src="https://d1s0l7jckv1cut.cloudfront.net/jewelry-ar/Fri-Jul-19-2024/logo.png"
                    className="w-[9.3%] h-auto object-contain sm:w-[20.5%] "
                    alt="logo"
                />
            </div>
        </div>
    );
};

export default Loader;
