import { useContext, useEffect, useState, useMemo } from "react";
import CustomizationOptions from "./CustomizationOptions";
import { AppContext } from "../Context/AppContextProvider";
import Discard from "./Discard";

const CustomizationMenu = () => {
    const context = useContext(AppContext);
    const [isInline, setIsInline] = useState<Boolean>(false);

    if (!context) {
        throw new Error("AppContext must be used within a AppContextProvider");
    }

    const {
        currentProductConfig,
        discardChanges,
        setSelectedCustomizationOptions,
    } = context;

    useEffect(() => {
        if (window.location.href.includes("customization-menu")) {
            setIsInline(true);
        }
    }, []);

    return (
        <div
            className={`${isInline ? "h-full" : "h-[1px] mt-[1.19rem] "
                } flex-grow sm:pr-[1.13rem] sm:ml-0 `}
        >
            {window.screen.width < 768 ? (
                ""
            ) : (
                <div className=" relative flex justify-end ">
                    {discardChanges && <Discard />}
                </div>
            )}
            <div
                className={`menu ${isInline ? "h-full" : "h-full"
                    } flex flex-col gap-[0.9rem] text-[14px] overflow-y-auto w-full overflow-x-hidden sm:h-full`}
            >
                {currentProductConfig.config?.materials?.map(
                    (category: any, index: number) => (
                        <CustomizationOptions
                            key={index}
                            category={category}
                            type="materials"
                            index={index}
                        />
                    )
                )}
                {currentProductConfig.config?.objects?.map(
                    (category: any, index: number) => (
                        <CustomizationOptions
                            key={index}
                            category={category}
                            type="objects"
                            index={index}
                            cascading={!!category?.key}
                        />
                    )
                )}
            </div>
        </div>
    );
};

export default CustomizationMenu;
