class IframeService {
    public isScriptDownloading: boolean;
    public scriptLoaded: boolean;

    constructor() {
        this.isScriptDownloading = false;
        this.scriptLoaded = false;
    }

    loadScript(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (!this.isScriptDownloading && !this.scriptLoaded) {
                const script = document.createElement('script');

                script.onload = () => {
                    console.log('script loaded');
                    this.scriptLoaded = true;
                    resolve(true);
                };

                script.onerror = (error) => {
                    console.error('script load error', error);
                    reject(error);
                };

                script.src = `${process.env.PUBLIC_URL}/customization-studio-integration.js`
                document.body.append(script);
                this.isScriptDownloading = true;
            } else {
                resolve(this.scriptLoaded);
            }
        });
    }
}

const iframeService = new IframeService();

export { iframeService as IframeService };
