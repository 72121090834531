import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppContextProvider } from './Context/AppContextProvider';
import { HashRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(

    <AppContextProvider>
        <HashRouter>
            <App />
        </HashRouter>
    </AppContextProvider>

);
