import React from "react";

interface PopupProps {
    title: string;
    description: string;
    primaryButtonName: string;
    discardButtonHandler: () => void;
    secondaryButtonName?: string;
    backButtonHandler?: () => void;
}

const Popup = ({
    title,
    description,
    primaryButtonName,
    discardButtonHandler,
    secondaryButtonName,
    backButtonHandler,
}: PopupProps) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-[rgba(28,28,28,0.20)] z-[100000]">
            <div className="bg-white max-w-[28.125rem] h-[13.75rem] rounded-[0.75881rem] shadow-lg sm:mx-[2rem] sm:h-auto ">
                <div className="flex items-center justify-center mt-[1.75rem] sm:mt-[1.31rem]">
                    <div className="text-[1rem] font-semibold  tracking-[-0.02rem]">
                        {title}
                    </div>
                </div>
                <div className="flex items-center justify-center mt-[2rem]  sm:mt-[1.25rem]">
                    <div className="text-[0.875rem]  font-normal text-center  tracking-[-0.0175rem] px-[3.25rem] sm:text-[0.75rem] sm:font-light sm:px-[3rem]">
                        {description}
                    </div>
                </div>
                <div className="flex justify-center space-x-[2.5rem] mt-[1.72rem] sm:mx-[1.38rem] sm:gap-[1rem] sm:space-x-0 sm:mb-[1.31rem]">
                    {secondaryButtonName ? (
                        <button
                            className="bg-[#F4F4F4] w-[35.5%] sm:w-full h-full max-w-[10rem] text-[0.875rem] text-black px-4 py-2 rounded-[0.375rem] sm:text-[0.75rem] sm:max-w-[40.5%] "
                            onClick={backButtonHandler}
                        >
                            {secondaryButtonName}
                        </button>
                    ) : null}
                    <button
                        className="bg-black w-[35.5%] sm:w-full h-full max-w-[10rem] text-[0.875rem] text-white px-4 py-2 rounded-[0.375rem] sm:font-semibold sm:text-[0.75rem] sm:tracking-[-0.015rem] sm:px-[1.25rem] sm:max-w-[40.5%]"
                        onClick={discardButtonHandler}
                    >
                        {primaryButtonName}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Popup;
