export const hexToRGB = (hexValue: string) => {
    // Remove # if present
    hexValue = hexValue.replace(/^#/, "");
    // Convert hex to RGB
    let bigint = parseInt(hexValue, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return `rgb(${r}, ${g}, ${b})`;
};
