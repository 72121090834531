import React, {
    useContext,
    useEffect,
    useRef,
    useState,
    forwardRef,
} from "react";
import { AppContext } from "../Context/AppContextProvider";
import { ThreeDViewer } from "../Services/Viewer";
import Loader from "./Loader";

const ThreeDProductViewer = () => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const context = useContext(AppContext);
    const [isInline, setIsInline] = useState<Boolean>(false);
    useEffect(() => {
        if (window.location.href.includes("3dViewer")) {
            setIsInline(true);
        }
    }, []);
    if (!context) {
        throw new Error("AppContext must be used within a AppContextProvider");
    }
    const {
        currentProductConfigCopy,
        setShowLoadingScreen,
        showLoadingScreen,
        isFullScreen,
        showToolTipInstructions,
    } = context;

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.data.origin === "customization-studio") {
                switch (event.data.function) {
                    case "applyMaterialVariation":
                        ThreeDViewer.applyMaterialVariation(
                            event.data.category,
                            event.data.selectedIndex,
                            () => {
                                console.log(
                                    "Material variation applied:",
                                    event.data
                                );
                            }
                        );
                        break;
                    case "applyObjectVariation":
                        ThreeDViewer.applyObjectVariation(
                            event.data.category,
                            event.data.selectedIndex,
                            () => {
                                console.log(
                                    "Object variation applied:",
                                    event.data
                                );
                            }
                        );
                        break;
                    default:
                        break;
                }
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            const handleMouseDown = () => {
                canvas.classList.add("cursor-grabbing");
            };

            const handleMouseUp = () => {
                canvas.classList.remove("cursor-grabbing");
                canvas.classList.add("cursor-grab");
            };

            const handleMouseLeave = () => {
                canvas.classList.remove("cursor-grabbing");
                canvas.classList.remove("cursor-grab");
            };

            const handleMouseEnter = () => {
                canvas.classList.add("cursor-grab");
            };

            canvas.addEventListener("mousedown", handleMouseDown);
            canvas.addEventListener("mouseup", handleMouseUp);
            canvas.addEventListener("mouseleave", handleMouseLeave);
            canvas.addEventListener("mouseenter", handleMouseEnter);

            return () => {
                canvas.removeEventListener("mousedown", handleMouseDown);
                canvas.removeEventListener("mouseup", handleMouseUp);
                canvas.removeEventListener("mouseenter", handleMouseEnter);
                canvas.removeEventListener("mouseleave", handleMouseLeave);
            };
        }
    }, []);

    useEffect(() => {
        if (Object.keys(currentProductConfigCopy).length > 0) {
            const initApp = async () => {
                await ThreeDViewer.setupViewer(
                    "webgi-canvas",
                    currentProductConfigCopy
                );
                setShowLoadingScreen(false);
            };
            initApp();
        }
    }, [currentProductConfigCopy]);
    return (
        <div
            className={`flex-grow overflow-hidden relative w-full h-full ${showToolTipInstructions
                ? "desktop-flow-step-1 flow-step-1 "
                : ""
                } `}
        >
            <canvas
                id="webgi-canvas"
                className={`${isFullScreen && window.screen.width < 768
                    ? "!w-[100%] bottom-[18.1%]"
                    : isFullScreen && window.screen.width > 768
                        ? "!w-full !h-full"
                        : "w-full h-full"
                    } absolute top-0 left-0 object-contain`}
                ref={canvasRef}
            ></canvas>

            {showLoadingScreen && isInline ? <Loader /> : ""}
        </div>
    );
};

export default ThreeDProductViewer;
