import React, { useEffect, useState } from 'react'
import { QueryParam } from '../Utilities/queryParam';
import { IframeService } from '../Services/IframeService/IframeService';
import { getData } from '../Utilities/sharedFunctions';
import { ApiService } from '../Services/apiServices';

const ProductList = () => {
    const [brandID, setBrandID] = useState<string>("");
    const [currentCategory, setCurrentCategory] = useState('')
    const [categoriesProducts, setcategoriesProducts] = useState<{ [key: string]: any }>({});
    const [error, setError] = useState(false)

    useEffect(() => {
        const brandId = QueryParam.getParam("brand_id") ?? "";
        if (brandId) {
            setBrandID(brandId);
        }

        getDataFromFirebase()
    }, []);

    const getDataFromFirebase = async () => {
        try {
            const idLoginResponse = await ApiService.userIdAuth();
            if (idLoginResponse && idLoginResponse.data) {
                const brandName = idLoginResponse.data.data.brandBucketName
                const data = await getData(`${brandName}`);
                if (data) {
                    getCategoriesData(data)
                }
            }
        } catch (error) {
            console.log('error')
            setError(true)
        }

    }

    const getCategoriesData = async (categories: any) => {
        let categoriesWithProducts: { [key: string]: any } = {}; // Define the type explicitly

        await Promise.all(
            Object.keys(categories).map(async (key) => {
                const productData = await getProductData(categories[key], key);
                categoriesWithProducts[key] = productData;
            })
        );
        setcategoriesProducts(categoriesWithProducts)
        const firstKey = Object.keys(categoriesWithProducts)[0];
        if (firstKey) {
            setCurrentCategory(firstKey);
        }
    }

    const getProductData = async (products: any, categoryKey: string) => {
        let productsData: any[] = [];

        for (const sku of Object.keys(products)) {
            const params = {
                limit: 20,
                product_code: sku,
                filter_field: {
                    page: 1,
                    isSetOnly: [false],
                },
            };

            try {
                const response = await ApiService.getCategoryProducts(params, categoryKey);
                if (response && response.status === 200 && response?.data?.data) {
                    if (response?.data?.data?.[0]?.variants?.[0]?.variantSku === sku) {
                        const data = response?.data?.data?.[0];
                        productsData.push(data);
                    }
                }
            } catch (error) {
                console.error(`Error fetching product data for SKU: ${sku}`, error);
            }
        }
        return productsData;
    };


    const handleTryOn = (sku: string) => {
        if (!IframeService.scriptLoaded) {
            IframeService.loadScript().then((loaded) => {
                window.customizationStudio.loadCustomizationStudioPopup(
                    brandID,
                    currentCategory,
                    sku
                );
            });
        } else {
            window.customizationStudio.loadCustomizationStudioPopup(
                brandID,
                currentCategory,
                sku
            );
        }
    };
    return (
        <div className='p-[1rem] flex flex-col gap-[3rem]'>
            <div className="flex w-full gap-[20px]">
                {Object.keys(categoriesProducts).map((key) => (
                    <div
                        key={key}
                        className={`${currentCategory === key ? 'bg-black' : 'bg-[gray]'} px-[1rem] py-[0.5rem] text-[white] rounded`}
                        onClick={() => setCurrentCategory(key)}
                    >
                        {key}
                    </div>
                ))}
            </div>
            <div className="flex flex-row sm:flex-row sm:justify-center flex-wrap gap-[2rem] overflow-y-auto flex-grow" style={{ maxHeight: 'calc(100vh - 130px)' }}>
                {categoriesProducts[currentCategory]?.map((product: any) => (
                    <div className="h-[18rem] w-[18rem] border p-[1rem] flex flex-col gap-[1rem] items-center rounded" key={product.productId}>
                        <img className="w-[10rem] h-[10rem] object-contain max-h-[10rem]" src={product.productImages[0]} alt={product.productHandle} />
                        <p>{product.variants[0].variantSku}</p>
                        <button className="bg-black text-white rounded px-[2rem] py-[0.5rem]" onClick={() => handleTryOn(product.variants[0].variantSku)}>Try On</button>
                    </div>
                ))}
            </div>
            {error ?
                <div className='z-[1000] confirmation-popup sm:fixed sm:left-0 absolute top-0 flex justify-center items-center h-full w-full bg-[rgba(0,0,0,0.45)] backdrop-blur-[2px] left-0'>
                    <div className='flex flex-col gap-[2.54rem] w-[23rem] sm:max-w-[80%] min-h-[9rem] h-fit max-h-1/2 rounded-[0.625rem] py-[2rem] px-3 bg-[rgba(0,0,0,0.80)]'>
                        <div className='flex flex-col items-center gap-[0.52rem]'>
                            <div className='text-[#FFF] text-center text-[1rem] font-semibold leading-[normal] tracking-[-0.02rem]'>
                                OOps something went wrong
                                <div className='text-[#FFF] text-center text-[0.75rem] font-semibold leading-[normal] tracking-[-0.015rem]'>
                                    Please try again
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                : ''}
        </div>
    )
}

export default ProductList
