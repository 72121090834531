import ThreeDProductViewer from "./ThreeDProductViewer";
import CommonSettings from "./Shared/CommonSettings";
import { useContext, useEffect, useRef, useState } from "react";
import Reset from "../Assests/reset.svg";
import Popup from "./Shared/Popup";
import { ThreeDViewer } from "../Services/Viewer";
import { AppContext } from "../Context/AppContextProvider";
import ShareLinkPopup from "./ShareLinkPopup";
import { Notification } from "./Notification";
import ShareIcon from "../Assests/share.svg";
import { SaveIcon } from "./Icons/Icons";
import { CiZoomIn } from "react-icons/ci";
import { CiZoomOut } from "react-icons/ci";

const ThreeDProductViewerInline = () => {
    const [showResetHandler, setShowResetHandler] = useState<boolean>(false);
    const context = useContext(AppContext);

    if (!context) {
        throw new Error("AppContext must be used within a AppContextProvider");
    }
    const { showShareLinkPopup, setShowShareLinkPopup, showNotification } =
        context;
    const handleResetClick = () => {
        setShowResetHandler(true);
    };
    const handleDiscardButtonClick = () => {
        setShowResetHandler(false);
        (ThreeDViewer as any).resetChanges();
        window.parent.postMessage({
            origin: "customization-studio",
            function: "discardChanges",
        });
    };

    const handleBackButtonClick = () => {
        setShowResetHandler(false);
    };

    const handleShareLink = () => {
        setShowShareLinkPopup(true);
    };

    const handleSave = async () => {
        ThreeDViewer.downloadScreenshot();
    };

    return (
        <div className="w-full h-full relative rounded-[0.6rem]">
            <CommonSettings />
            {/* <div className="flex bottom-[0.81rem]  justify-center   w-full items-center gap-[1.88rem]  absolute">
                <div
                    className="flex flex-col items-center cursor-pointer  left-[0.31rem] top-[0.54rem] z-10"
                    id="reset"
                    onClick={handleResetClick}
                >
                    <img
                        src={Reset}
                        className="w-[1.5rem] sm:w-[1.25rem]"
                        alt="Reset"
                    />
                    <div className="text-[0.7875rem] sm:text-[0.5625rem]">
                        Reset
                    </div>
                </div>
                <div
                    className=" z-[100] top-[4.67rem] sm:top-[3.69rem] right-0 flex flex-col items-center cursor-pointer"
                    id="save"
                    onClick={handleSave}
                >
                    <SaveIcon />
                    <div className="text-[0.7875rem] sm:text-[0.5625rem]">
                        Save
                    </div>
                </div>

                <div
                    className=" z-[100] top-[4.67rem] sm:top-[3.69rem] pl-[0.56rem] flex flex-col items-center cursor-pointer"
                    id="Share"
                    onClick={handleShareLink}
                >
                    <img src={ShareIcon} className="w-[1.5rem]" alt="Share" />
                    <div className="text-[0.7875rem] sm:text-[0.5625rem]">
                        Share
                    </div>
                </div>
            </div> */}

            <ThreeDProductViewer />
            {showShareLinkPopup ? <ShareLinkPopup /> : ""}
            {showNotification ? <Notification /> : ""}
            {showResetHandler && (
                <Popup
                    title="Discard Changes ?"
                    description="You have unsaved changes. Are you sure you want to discard all the changes ?"
                    primaryButtonName="Discard"
                    discardButtonHandler={handleDiscardButtonClick}
                    secondaryButtonName="Back"
                    backButtonHandler={handleBackButtonClick}
                />
            )}
        </div>
    );
};

export default ThreeDProductViewerInline;
