import { useContext, useEffect, useRef, useState } from "react";
import { ThreeDViewer } from "../Services/Viewer";
import { AppContext } from "../Context/AppContextProvider";
import leftArrow from "../Assests/Left-arrow.svg";
import rightArrow from "../Assests/Right-arrow.svg";
import { update } from "firebase/database";

interface Config {
    icon: string;
    icons: string[];
    items: string[];
    name: string;
    prefix: string;
    selected?: number;
    title: string;
    key?: string[];
    titles: string[];
    cascadingIcons?: any;
}

interface CustomizationOptionsProps {
    category: Config;
    key: number;
    type: string;
    index: number;
    cascading?: any;
}

const CustomizationOptions = ({
    category,
    type,
    index,
    cascading,
}: CustomizationOptionsProps) => {
    const context = useContext(AppContext);
    const [isInline, setIsInline] = useState<Boolean>(false);
    const itemRefs = useRef<Array<HTMLDivElement | null>>([]);

    useEffect(() => {
        if (window.location.href.includes("customization-menu")) {
            setIsInline(true);
        }
    }, []);
    if (!context) {
        throw new Error("AppContext must be used within an AppContextProvider");
    }

    const [customizationChanged, setCustomizationChanged] = useState(false);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);
    const scrollRef = useRef<HTMLDivElement>(null);
    const [cascadingFormat, setCascadingFormat] = useState<any>();
    const [cascadingSelectedKey, setCascadingSelectedKey] = useState<any>();

    const {
        setCurrentProductConfig,
        currentProductConfigCopy,
        currentProductConfig,
        setDiscardChanges,
        discardChanges,
    } = context;

    const { items, titles, selected, cascadingIcons } = category;

    useEffect(() => {
        if (cascading) {
            console.log(category);
            const format = category.key?.reduce((a: any, b: any, c: number) => {
                console.log(a, b, c);
                const title = b.split("_");
                if (a[title[0]]) {
                    a[title[0]] = [
                        ...a[title[0]],
                        {
                            title: category.titles?.[c],
                            index: c,
                        },
                    ];
                } else {
                    a[title[0]] = [
                        {
                            title: category.titles?.[c],
                            index: c,
                        },
                    ];
                }
                return a;
            }, {});
            Object.keys(format).map((key, index) => {
                format[key].map((item: any) => {
                    if (item.index == selected) {
                        setCascadingSelectedKey(Object.keys(format)[index]);
                    }
                })
            })
            setCascadingFormat(format);
        }
    }, [cascading]);

    const sendMessageToChangeMaterialVariant = (
        categoryName: string,
        selectedIndex: number
    ) => {
        console.log("called sendMessageToChangeMaterialVariant");
        window.parent.postMessage({
            origin: "customization-studio",
            function: "sendMessageToChangeMaterialVariant",
            category: categoryName,
            selectedIndex: selectedIndex,
        });
    };

    const sendMessageToChangeObjectVariant = (
        categoryName: string,
        selectedIndex: number
    ) => {
        console.log("called sendMessageToChangeObjectVariant");
        window.parent.postMessage({
            origin: "customization-studio",
            function: "sendMessageToChangeObjectVariant",
            category: categoryName,
            selectedIndex: selectedIndex,
        });
    };

    const handleScroll = () => {
        const element = scrollRef.current;
        if (element) {
            setShowLeftArrow(element.scrollLeft > 0);
            setShowRightArrow(
                Math.round(element.scrollLeft) <
                Math.round(element.scrollWidth) -
                Math.round(element.clientWidth)
            );
        }
    };

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: -200, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: 200, behavior: "smooth" });
        }
    };

    const updateConfig = (selectedIndex: number) => {
        setCurrentProductConfig((prevConfig: any) => {
            const updatedValue = [...prevConfig.config[type]];
            updatedValue[index] = {
                ...updatedValue[index],
                selected: selectedIndex,
            };
            return {
                ...prevConfig,
                config: {
                    ...prevConfig.config,
                    [type]: updatedValue,
                },
            };
        });
    };

    useEffect(() => {
        handleScroll();
    }, [customizationChanged]);

    const handleCustomization = (selectedIndex: number) => {
        switch (type) {
            case "materials":
                if (isInline) {
                    sendMessageToChangeMaterialVariant(
                        category.name,
                        selectedIndex
                    );
                }
                updateConfig(selectedIndex);
                ThreeDViewer.applyMaterialVariation(
                    category.name,
                    selectedIndex,
                    () => {
                        setCustomizationChanged(!customizationChanged);
                    }
                );
                scrollToItemHorizontally(selectedIndex);
                break;
            case "objects":
                if (isInline) {
                    sendMessageToChangeObjectVariant(
                        category.name,
                        selectedIndex
                    );
                }
                updateConfig(selectedIndex);
                ThreeDViewer.applyObjectVariation(
                    category.name,
                    selectedIndex,
                    () => {
                        setCustomizationChanged(!customizationChanged);
                    }
                );
                scrollToItemHorizontally(selectedIndex);
                break;
        }
    };

    console.log("category", category);

    const handleCascadingCustomization = (item: any) => {
        handleCustomization(item.index);
    };

    const handleCascadingTitleChange = (title: string) => {
        const newCascadingElement = cascadingFormat[title].find(
            (item: any) => selected && item.title === titles[selected]
        );
        setCascadingSelectedKey(title);
        if (newCascadingElement) handleCustomization(newCascadingElement.index);
        else handleCustomization(cascadingFormat[title][0].index);
    };

    const scrollToItemHorizontally = (selectedIndex: number) => {
        const container = scrollRef.current;
        const item = itemRefs.current[selectedIndex];

        if (container && item) {
            const containerRect = container.getBoundingClientRect();
            const itemRect = item.getBoundingClientRect();

            // Check if the item is outside the visible bounds of the container
            const isItemInView =
                itemRect.left >= containerRect.left &&
                itemRect.right <= containerRect.right;

            // If the item is not in view, scroll to bring it into view
            if (!isItemInView) {
                const scrollLeft =
                    itemRect.left -
                    containerRect.left +
                    container.scrollLeft -
                    (containerRect.width / 2 - itemRect.width / 2);

                // Smooth horizontal scroll
                container.scrollTo({
                    left: scrollLeft,
                    behavior: "smooth",
                });
            }
        }
    };

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.data.origin === "customization-studio") {
                switch (event.data.function) {
                    case "discardChanges":
                        setCurrentProductConfig(currentProductConfigCopy);
                        setDiscardChanges(true);
                        break;
                    default:
                        break;
                }
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    useEffect(() => {
        if (discardChanges) {
            const timer = setTimeout(() => {
                setDiscardChanges(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [discardChanges]);

    console.log(category.icons);

    return (
        (cascading || (items?.length > 1)) && (
            <div className="flex flex-col relative">
                <p className="text-[0.9375rem] text-[#5D5D5D] mb-[0.30rem] sm:font-semibold sm:mb-[0.56rem] ">
                    {category.title}
                </p>
                <div className="relative">
                    {showLeftArrow && (
                        <div className="custom-blur absolute left-[-8px] top-[50%] transform -translate-y-1/2 cursor-pointer z-[100] h-[5.875rem] w-[1.875rem] flex justify-center items-center">
                            <img
                                src={leftArrow}
                                alt="Left Arrow"
                                className=""
                                onClick={scrollLeft}
                            />
                        </div>
                    )}
                    <div
                        className="overflow-x-auto overflow-y-hidden hide-scrollbar-x"
                        ref={scrollRef}
                        onScroll={handleScroll}
                    >
                        {cascading && cascadingFormat ? (
                            <>
                                <div className="flex gap-1 mb-[0.4rem]">
                                    {Object.keys(cascadingFormat)?.map(
                                        (parentKey: string, key) => (
                                            <div
                                                key={`parent-${type}-${index}-${key}`}
                                                // className={`p-2 border ${cascadingSelectedKey === parentKey ? 'border-2' : ""}`}
                                                className={`w-[5.3125rem] object-contain object-center h-[5.3125rem]  border-[1px] rounded-[0.441rem] cursor-pointer sm:w-[5rem] sm:h-[5rem] sm:items-center flex justify-center items-center sm:flex 
                                                    ${cascadingSelectedKey === parentKey
                                                        ? "border-[1px] border-[var(--color-secondary)]"
                                                        : "border-0 border-[rgb(254,254,254)]"
                                                    }`}
                                                onClick={() =>
                                                    handleCascadingTitleChange(
                                                        parentKey
                                                    )
                                                }
                                            >
                                                {cascadingIcons?.[parentKey] ? (
                                                    <img
                                                        src={
                                                            cascadingIcons?.[
                                                            parentKey
                                                            ]
                                                        }
                                                        alt={parentKey}
                                                    />
                                                ) : (
                                                    <span>{parentKey}</span>
                                                )}
                                            </div>
                                        )
                                    )}
                                </div>
                                <p
                                    className={`text-[0.9375rem] text-[#5D5D5D] ${cascading
                                        ? "mb-[0.4rem]"
                                        : "mb-[0.93rem]"
                                        }  sm:font-semibold`}
                                >
                                    {"Center Size Variations"}
                                </p>
                                <div className="flex">
                                    {cascadingFormat[cascadingSelectedKey].map(
                                        (item: any, key: number) => (
                                            <div
                                                id="view3"
                                                key={`${cascadingSelectedKey}-${item.index}`}
                                                ref={(el) =>
                                                (itemRefs.current[index] =
                                                    el)
                                                }
                                                className="flex flex-col gap-[5px] items-center"
                                                onClick={() =>
                                                    handleCascadingCustomization(
                                                        item
                                                    )
                                                }
                                            >
                                                <div className="flex ">
                                                    <div
                                                        className={`w-[5.3125rem] h-[5.3125rem]  border-[1px] rounded-[0.441rem] cursor-pointer sm:w-[5rem] sm:h-[5rem] sm:items-center flex justify-center items-center sm:flex 
                                                            ${item.index !== selected
                                                                ? "border-0 border-[rgb(254,254,254)]"
                                                                : "border-[1px] border-[var(--color-secondary)]"
                                                            }`}
                                                    >
                                                        <div className="flex flex-col items-center pt-[0.19rem]  sm:pt-[0.31rem] sm:w-[100%] truncate h-full">
                                                            <img
                                                                src={
                                                                    category
                                                                        .icons?.[
                                                                    item
                                                                        .index
                                                                    ]
                                                                }
                                                                className="w-[58.6%] h-auto aspect-square object-contain flex-grow object-center"
                                                                alt={
                                                                    titles[
                                                                    item
                                                                        .index
                                                                    ]
                                                                }
                                                            />
                                                            <span
                                                                title={
                                                                    titles[
                                                                    index
                                                                    ]
                                                                }
                                                                className="text-[0.75rem] mt-[0.19rem] text-[#000] w-[4rem] truncate  text-center sm:text-[0.875rem] sm:mt-[0.26rem] "
                                                            >
                                                                {
                                                                    titles[
                                                                    item
                                                                        .index
                                                                    ]
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </>
                        ) : (
                            <div className="flex">
                                {items.map((item, index) => (
                                    <div
                                        id="view3"
                                        key={index}
                                        ref={(el) =>
                                            (itemRefs.current[index] = el)
                                        }
                                        className="flex flex-col gap-[5px] items-center"
                                        onClick={() =>
                                            handleCustomization(index)
                                        }
                                    >
                                        <div className="flex">
                                            <div
                                                className={`w-[5.3125rem] h-[5.3125rem]  border-[1px] rounded-[0.441rem] cursor-pointer sm:w-[5rem] sm:h-[5rem] sm:items-center flex justify-center items-center sm:flex ${index !== selected
                                                    ? "border-0 border-[rgb(254,254,254)]"
                                                    : "border-[1px] border-[var(--color-secondary)]"
                                                    }`}
                                            >
                                                <div className="flex flex-col items-center pt-[0.19rem] h-full sm:pt-[0.31rem] sm:w-[100%] truncate">
                                                    <img
                                                        src={
                                                            category.icons?.[
                                                            index
                                                            ]
                                                        }
                                                        className="w-[58.6%] h-auto flex-grow object-center aspect-square object-contain"
                                                        alt={titles[index]}
                                                    />
                                                    <span
                                                        title={titles[index]}
                                                        className="text-[0.75rem] mt-[0.19rem] text-[#000] w-[4rem] truncate text-center sm:text-[0.75rem] sm:mt-[0.26rem] "
                                                    >
                                                        {titles[index]}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {/* {showRightArrow && (
                    <div className="custom-blur absolute right-[-8px] top-[50%] transform -translate-y-1/2 cursor-pointer z-[100]  h-[5.875rem] w-[1.875rem] flex justify-center items-center ">
                        <img
                            src={rightArrow}
                            alt="Right Arrow"
                            className=""
                            onClick={scrollRight}
                        />
                    </div>
                )} */}
                </div>
            </div>
        )
    );
};

export default CustomizationOptions;
