import { ApiService } from "../Services/apiServices";
import { hexToRGB } from "./colorConverter";
interface ToggleFeatures { }

interface BrandData {
    settingBrandId?: string;
    settingThemeColor?: string;
    settingMetaJson?: {
        ui_components?: {
            [key: string]: boolean;
        };
    };
    [key: string]: any;
}

class BrandDetails {
    public brandData: BrandData;
    public toggleFeatures: ToggleFeatures;
    public brandDetailsSetterCallback: (brandData: BrandData) => void;

    constructor() {
        this.brandData = {};
        this.toggleFeatures = {};
        this.brandDetailsSetterCallback = () => { };
    }

    setBrandDetails = async (): Promise<boolean | undefined> => {
        try {
            const idLoginResponse = await ApiService.userIdAuth();

            if (
                idLoginResponse &&
                idLoginResponse.status === 200 &&
                idLoginResponse.data?.data
            ) {
                this.brandData = {
                    ...idLoginResponse.data.data,
                    settingBrandId:
                        idLoginResponse.data.data.brandDetails?.brandId,
                };
                this.brandDetailsSetterCallback(this.brandData);
                this.setSecondaryColor(idLoginResponse.data?.data);
                this.setToggleFeatureValues(
                    idLoginResponse.data.data.settingMetaJson?.ui_components ||
                    {}
                );
                return true;
            }
        } catch (error) {
            console.error("Failed to set brand details:", error);
        }
    };

    setToggleFeatureValues = (features: { [key: string]: boolean }): void => {
        for (const feature in features) {
            if (
                Object.prototype.hasOwnProperty.call(
                    this.toggleFeatures,
                    feature
                )
            ) {
                // this.toggleFeatures[feature] = features[feature];
            }
        }
    };

    brandDetailsSetter = (callback: (brandData: BrandData) => void): void => {
        this.brandDetailsSetterCallback = callback;
    };
    setSecondaryColor = (data: BrandData) => {
        const secondaryColor = data?.settingThemeColor || "#832728";
        const secondaryColorRGBValue = hexToRGB(secondaryColor);
        document.documentElement.style.setProperty(
            "--color-secondary",
            secondaryColorRGBValue
        );
    };
}

const brandDetails = new BrandDetails();
export { brandDetails as BrandDetails };
