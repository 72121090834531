export const CopyIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
        <path d="M13.6441 5.43994H7.66309C6.92905 5.43994 6.33398 5.9772 6.33398 6.63993V12.0399C6.33398 12.7026 6.92905 13.2399 7.66309 13.2399H13.6441C14.3781 13.2399 14.9732 12.7026 14.9732 12.0399V6.63993C14.9732 5.9772 14.3781 5.43994 13.6441 5.43994Z" stroke="black" strokeWidth="1.49999" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.67725 9.04019H3.0127C2.6602 9.04019 2.32214 8.91376 2.07288 8.68872C1.82362 8.46367 1.68359 8.15845 1.68359 7.84019V2.44023C1.68359 2.12197 1.82362 1.81675 2.07288 1.5917C2.32214 1.36666 2.6602 1.24023 3.0127 1.24023H8.99368C9.34618 1.24023 9.68424 1.36666 9.9335 1.5917C10.1828 1.81675 10.3228 2.12197 10.3228 2.44023V3.04022" stroke="black" strokeWidth="1.49999" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
}

export const SaveIcon = () => {
    return <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill="#D9D9D9" />
        <g clip-path="url(#clip0_1431_798)">
            <path d="M20.9 20.67V20.9114L21.0707 20.7407L22.9507 18.8607C23.3017 18.5098 23.8683 18.5098 24.2193 18.8607C24.5702 19.2117 24.5702 19.7783 24.2193 20.1293L20.6293 23.7193C20.2783 24.0702 19.7117 24.0702 19.3607 23.7193L15.7707 20.1293C15.4198 19.7783 15.4198 19.2117 15.7707 18.8607C16.1217 18.5098 16.6883 18.5098 17.0393 18.8607L17.0395 18.8609L18.9295 20.7409L19.1 20.9105V20.67V12C19.1 11.5052 19.5052 11.1 20 11.1C20.4948 11.1 20.9 11.5052 20.9 12V20.67ZM27.1 26V21C27.1 20.5052 27.5052 20.1 28 20.1C28.4948 20.1 28.9 20.5052 28.9 21V27C28.9 28.0448 28.0448 28.9 27 28.9H13C11.9552 28.9 11.1 28.0448 11.1 27V21C11.1 20.5052 11.5052 20.1 12 20.1C12.4948 20.1 12.9 20.5052 12.9 21V26C12.9 26.6052 13.3948 27.1 14 27.1H26C26.6052 27.1 27.1 26.6052 27.1 26Z" fill="black" stroke="#D9D9D9" stroke-width="0.2" />
        </g>
        <defs>
            <clipPath id="clip0_1431_798">
                <rect width="24" height="24" fill="white" transform="translate(8 8)" />
            </clipPath>
        </defs>
    </svg>

}

export const ShareLinkPopupCloseIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none" className="close-popup">
        <g clipPath="url(#clip0_901_526)">
            <path d="M6.4252 5.48573L10.6095 1.3013C10.673 1.23996 10.7236 1.16659 10.7585 1.08546C10.7933 1.00434 10.8117 0.917084 10.8124 0.828794C10.8132 0.740505 10.7964 0.652946 10.763 0.571228C10.7295 0.48951 10.6801 0.415268 10.6177 0.352836C10.5553 0.290403 10.481 0.24103 10.3993 0.207596C10.3176 0.174163 10.23 0.157339 10.1418 0.158106C10.0535 0.158873 9.96621 0.177216 9.88509 0.212065C9.80396 0.246914 9.73059 0.29757 9.66925 0.361078L5.48482 4.54535L1.30055 0.361078C1.17539 0.238672 1.00701 0.17056 0.831952 0.171524C0.656892 0.172488 0.489274 0.24245 0.365475 0.366227C0.241676 0.490005 0.171685 0.65761 0.17069 0.83267C0.169695 1.00773 0.237776 1.17612 0.360161 1.3013L4.5446 5.48573L0.360161 9.67017C0.297286 9.73166 0.247235 9.80502 0.212906 9.88599C0.178577 9.96696 0.160651 10.0539 0.160166 10.1419C0.159682 10.2298 0.176649 10.317 0.210084 10.3983C0.24352 10.4797 0.29276 10.5536 0.354953 10.6158C0.417146 10.6779 0.491057 10.7272 0.572406 10.7606C0.653755 10.794 0.740926 10.811 0.828871 10.8105C0.916816 10.81 1.00379 10.792 1.08475 10.7577C1.16572 10.7233 1.23906 10.6733 1.30055 10.6104L5.48482 6.42612L9.66925 10.6104C9.79908 10.7402 9.96931 10.8052 10.1394 10.8052C10.3094 10.8052 10.4798 10.7402 10.6095 10.6104C10.7341 10.4857 10.8042 10.3166 10.8042 10.1403C10.8042 9.96396 10.7341 9.79486 10.6095 9.67017L6.4252 5.48573Z" fill="black" />
        </g>
        <defs>
            <clipPath id="clip0_901_526">
                <rect width="10.9714" height="10.9714" fill="white" />
            </clipPath>
        </defs>
    </svg>
}