import { ThreeDViewer } from "../Services/Viewer";

import { onValue, ref } from "firebase/database";
import db from "../Services/Firebase/Config";

export const getShareableLink = () => {
    let currentUrl = `${window.parent.location.href}&selectedConfig=`;

    const selectedConfigRegex = /&selectedConfig=[^&]*/g;
    const requiredDataRegex = /&requiredData=[^&]*/g;
    const isInlineRegex = /&isInline=[^&]*/g;

    currentUrl = currentUrl.replaceAll(selectedConfigRegex, "");
    currentUrl = currentUrl.replaceAll(requiredDataRegex, "");
    currentUrl = currentUrl.replaceAll(isInlineRegex, "");
    if (!window.parent?.customizationStudio) {
        currentUrl = `${currentUrl}?selectedConfig=`;
    }


    let url = "";
    Object.keys(ThreeDViewer.config?.variations || {}).map((config) => {
        if (config !== "objects" && config !== "materials") return;
        ThreeDViewer.config?.variations[config].map((property: any) => {
            if (url) {
                url = `${url}%${config}-${property.name}-${property.selected}`;
            } else {
                url = `${config}-${property.name}-${property.selected}`;
            }
        });
    });
    if (!window.parent?.customizationStudio) {
        return currentUrl + url
    }
    const brandId = window.parent?.customizationStudio?.brandId;
    const sku = window.parent?.customizationStudio?.sku;
    const category = window.parent?.customizationStudio?.category;
    const isInline = window.parent?.customizationStudio?.isInline;
    const threeDViewerIframeParentId =
        window.parent?.customizationStudio?.threeDViewerIframeParentId;
    const customizationMenuIframeParentId =
        window.parent.customizationStudio?.customizationMenuIframeParentId;
    let newURL = "";
    if (isInline) {
        newURL = `${currentUrl + url
            }&requiredData=brand_id=${brandId}#category=${category}#sku=${sku}#threeDViewerIframeParentId=${threeDViewerIframeParentId}#customizationMenuIframeParentId=${customizationMenuIframeParentId}&isInline=true`;
    } else {
        newURL = `${currentUrl + url
            }&requiredData=brand_id=${brandId}#category=${category}#sku=${sku}`;
    }
    return newURL;
};

interface SelectedConfig {
    [key: string]: {
        icon: string;
        label: string;
    };
}

export const getSelectedConfig = (config: any) => {
    const selectedConfig: SelectedConfig = {};
    Object.keys(config).forEach((key) => {
        const items: any[] = config[key];
        items.forEach((item: any) => {
            const selectedIndex = item.selected;
            selectedConfig[item.title] = {
                icon: item.icons[selectedIndex],
                label: item.titles[selectedIndex],
            };
        });
    });
    return selectedConfig;
};

export const getData = async (path: string): Promise<any> => {
    return new Promise((resolve, reject) => {
        try {
            const reference = ref(db, path);
            onValue(reference, (snapshot) => {
                const data = snapshot.val();
                resolve(data); // Let the data be any for now
            });
        } catch (error) {
            console.error("Error fetching data:", error);
            reject(error);
        }
    });
};

export const getCurrentSelectedConfigs = (config: any) => {
    let arr: Array<{ [key: string]: string }> = [];
    Object.keys(config).forEach((key) => {
        const items: any[] = config[key];
        items.forEach((item: any) => {
            const selectedIndex = item.selected;
            const obj = {
                [item.title]: item.titles[selectedIndex],
            };
            arr.push(obj);
        });
    });
    return arr;
};
